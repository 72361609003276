











import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {BIconShield, VBTooltip} from 'bootstrap-vue';

@Component({
    name: "FullValidatorTitle",
    components: {BIconShield},
    directives: {'b-tooltip': VBTooltip}
})
export default class FullValidatorTitle extends Vue {
    @Prop()
    protected node!: Node;
}
